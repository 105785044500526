
import React from 'react';

import {
    CardProps
} from "./CardProps";

import {
    Button,
    Columns
} from "react-bulma-components";

import {
    ManageScopes
} from "../addCards/scope/ManageScopes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faSave, faTrashCan} from "@fortawesome/free-solid-svg-icons";

interface ScopeProps{
    'sites': string[];
}

interface State{
    chosenWebsitesNames: string[];
    isChanging: boolean;
}


export class Scope extends React.Component<CardProps, State>{

    constructor(props: CardProps) {
        super(props);

        this.state = {
            chosenWebsitesNames: (this.props.data as ScopeProps).sites,
            isChanging: false
        };
    }

    componentWillUpdate(nextProps: Readonly<CardProps>, nextState: Readonly<State>, nextContext: any) {
        if (nextProps.id !== this.props.id){
            this.setState({
                chosenWebsitesNames: (nextProps.data as ScopeProps).sites,
                isChanging: false
            });
        }
    }

    render(){
        const props = this.props.data as ScopeProps;

        if (this.state.isChanging){
            return (
                <Columns className="is-multiline">
                    <Columns.Column className="is-12">
                        <ManageScopes
                            allWebsitesNames={Object.keys(this.props.totalMetadata.sites)}
                            chosenWebsitesNames={this.state.chosenWebsitesNames}
                            setChosenWebsitesNames={(names: string[]) => {
                                this.setState({
                                    chosenWebsitesNames: names
                                });
                            }}
                        />
                    </Columns.Column>

                    <Columns.Column className="is-12 mt-6">
                        <div className="buttons">
                            <Button
                                className="is-success is-rounded"
                                onClick={async () => {
                                    const success = await this.props.sendRequest(
                                        'PUT',
                                        `admin/scope/${this.props.id}/`,
                                        {
                                            sites: this.state.chosenWebsitesNames
                                        },

                                        `Change the Scope: ${this.props.id} ?`,
                                        () => {
                                            this.setState({isChanging: false});
                                        }
                                    );
                                }}
                            >
                                <span>
                                    <FontAwesomeIcon icon={faSave} className="mr-3"/>
                                </span>
                                <span>
                                    Save
                                </span>
                            </Button>

                            <Button
                                className="is-danger is-rounded"
                                onClick={() => {
                                    this.setState({isChanging: false});
                                }}
                            >
                                Cancel
                            </Button>

                            {
                                this.props.id !== 'Admin'?
                                    <Button
                                        className="is-danger is-rounded"
                                        onClick={async () => {
                                            const success = await this.props.sendRequest(
                                                'DELETE',
                                                `admin/scope/${this.props.id}/`,
                                                {},
                                                `Delete Scope: ${this.props.id} ?`,
                                                () => {
                                                    this.props.setShownCardIdentifier(null);
                                                }
                                            );
                                        }}
                                    >
                                    <span>
                                        <FontAwesomeIcon icon={faTrashCan} className="mr-3"/>
                                    </span>
                                        <span>
                                        Delete Scope
                                    </span>
                                    </Button>
                                    :
                                    null
                            }
                        </div>
                    </Columns.Column>


                </Columns>
            );
        }

        // else:

        return (
            <Columns className="is-multiline">
                {
                    props.sites.map(
                        (siteName: string, index: number) => {
                            const backgroundColor = {
                                0: 'primary',
                                1: 'link',
                                2: 'warning',
                                3: 'info',
                                4: 'danger',
                                5: 'success'
                            }[index % 6];
                            return (
                                <Columns.Column
                                    className={`box has-background-${backgroundColor} is-12`} key={index}
                                >
                                    {siteName}
                                </Columns.Column>
                            );
                        }
                    )
                }

                <Columns.Column className="is-12 mt-6">
                    <div className="buttons">
                        <Button
                            className="is-success is-rounded"
                            onClick={() => {
                                this.setState({isChanging: true});
                            }}
                        >
                            <span>
                                <FontAwesomeIcon icon={faPencil} className="mr-3"/>
                            </span>
                            <span>
                                Edit
                            </span>
                        </Button>
                    </div>
                </Columns.Column>
            </Columns>
        );
    }
}