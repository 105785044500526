
import React from 'react';

import {
    AddCardProps
} from "./AddCardProps";

import {
    Columns,
    Button
} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faHatCowboy, faSave} from "@fortawesome/free-solid-svg-icons";



interface State{
    email: string;
    scope: string;
}


export class AddUser extends React.Component<AddCardProps, State>{

    constructor(props: AddCardProps) {
        super(props);

        this.state = {
            email: '',
            scope: Object.keys(props.totalMetadata.scopes).at(0) as string
        };
    }

    render(): JSX.Element{
        const allScopes = Object.keys(this.props.totalMetadata.scopes);

        return (
            <>
                <Columns className="is-multiline user">

                    <Columns.Column className="is-7 email">
                        <div className="control has-icons-left has-icons-right">
                            <input
                                className="input is-rounded"
                                type="text"
                                placeholder="Email"
                                onChange={(event) => {
                                    this.setState({
                                        email: event.target.value,
                                    });
                                }}
                            />

                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                        </div>
                    </Columns.Column>

                    <Columns.Column className="is-5 scope">
                        <div className="control has-icons-left">
                            <div className="select is-rounded" style={{position: 'relative', float: 'left'}}>
                                <select
                                    onChange={(event) => {
                                        this.setState({
                                            scope: event.target.value
                                        });
                                    }}
                                >
                                    <option value={this.state.scope}>{this.state.scope}</option>
                                    {
                                        allScopes.map(
                                            (scopeName: string, index: number) => {
                                                if (scopeName === this.state.scope) {
                                                    return null;
                                                }

                                                return (
                                                    <option value={scopeName} key={index}>
                                                        {scopeName}
                                                    </option>
                                                );
                                            }
                                        )
                                    }
                                </select>
                            </div>

                            <div className="icon is-small is-left">
                                <FontAwesomeIcon icon={faHatCowboy} />
                            </div>
                        </div>
                    </Columns.Column>

                    <Columns.Column className="is-12 mt-6">
                        <div className="buttons">
                            <Button
                                className="is-success is-rounded"
                                onClick={async () => {
                                    const success = await this.props.sendRequest(
                                        'POST',
                                        `admin/user/${this.state.email}/`,
                                        {
                                            scope: this.state.scope
                                        },

                                        `Add Email: ${this.state.email}, with Scope: ${this.state.scope} ?`,
                                        () => {
                                            this.props.setShownCardIdentifier(this.state.email);
                                            this.props.setItemAdded(false);
                                        }
                                    );
                                }}
                            >
                                <span>
                                    <FontAwesomeIcon icon={faSave} className="mr-3"/>
                                </span>
                                <span>
                                    Save
                                </span>
                            </Button>

                            <Button
                                className="is-danger is-rounded"
                                onClick={() => {
                                    this.props.setItemAdded(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Columns.Column>
                </Columns>
            </>
        )
    }
}