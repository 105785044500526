
import React from 'react';

import {
    AddCardProps
} from "../AddCardProps";

import {
    ManageScopes
} from "./ManageScopes";

import {
    Columns,
    Button
} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faSave} from "@fortawesome/free-solid-svg-icons";



interface State{
    name: string;
    chosenWebsitesNames: string[];
}


export class AddScope extends React.Component<AddCardProps, State>{

    constructor(props: AddCardProps) {
        super(props);

        this.state = {
            name: '',
            chosenWebsitesNames: []
        };
    }

    render(): JSX.Element{

        return (
            <>
                <Columns className="is-multiline scope">

                    <Columns.Column className="is-5 email">
                        <div className="control has-icons-left has-icons-right">
                            <input
                                className="input is-rounded"
                                type="text"
                                placeholder="Scope Name"
                                onChange={(event) => {
                                    this.setState({
                                        name: event.target.value,
                                    });
                                }}
                            />

                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faPencil} />
                            </span>
                        </div>
                    </Columns.Column>

                    <Columns.Column className="is-12 scope">
                        <ManageScopes
                            allWebsitesNames={Object.keys(this.props.totalMetadata.sites)}
                            chosenWebsitesNames={this.state.chosenWebsitesNames}
                            setChosenWebsitesNames={(names: string[]) => this.setState({chosenWebsitesNames: names})}
                        />
                    </Columns.Column>

                    <Columns.Column className="is-12 mt-6">
                        <div className="buttons">
                            <Button
                                className="is-success is-rounded"
                                onClick={async() => {
                                    const success = await this.props.sendRequest(
                                        'POST',
                                        `admin/scope/${this.state.name}/`,
                                        {
                                            sites: this.state.chosenWebsitesNames
                                        },
                                        `Add Scope: ${this.state.name} ?`,
                                        () => {
                                            this.props.setShownCardIdentifier(this.state.name);
                                            this.props.setItemAdded(false);
                                        }
                                    );
                                }}
                            >
                                <span>
                                    <FontAwesomeIcon icon={faSave} className="mr-3"/>
                                </span>
                                <span>
                                    Save
                                </span>
                            </Button>

                            <Button
                                className="is-danger is-rounded"
                                onClick={() => {
                                    this.props.setItemAdded(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Columns.Column>
                </Columns>
            </>
        )
    }
}