
import 'bulma/css/bulma.min.css';
import './Navbar.css';
import '../general_styles.css';

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faHatCowboy, faGlobe} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bulma-components";


interface Props{
    selectedTab: 'users' | 'scopes' | 'sites';
    setSelectedTab: Function;
}

interface State{
    menuActive: boolean;
}

class Navbar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            menuActive: false
        };
    }

    renderTab(
        name: string
    ): JSX.Element
    {
        return (
            <Button
                className={"navbar-item mt-2 mr-4 is-rounded clickable" + (name === this.props.selectedTab ? ' is-success' : '')}
                onClick={() => {
                    this.props.setSelectedTab(name);
                }}
            >
                <label className="mr-1">{name.charAt(0).toUpperCase() + name.slice(1)}</label>
                <FontAwesomeIcon icon={name === 'users' ? faUser : (name === 'scopes' ? faHatCowboy : faGlobe)} />
            </Button>
        );
    }

    render(){
        return (
            <nav className="navbar has-background-white-ter" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <div className="navbar-item spin" ><img src={require('../img/navbar_brand.png')} width="28" height="28" /></div>

                    <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
                       data-target="navbarBasicExample"
                       onClick={() => {
                           this.setState({menuActive: !this.state.menuActive})
                       }}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id='nav-menu' className={"navbar-menu" + (this.state.menuActive ? ' is-active' : '')}>
                    <div className="navbar-start">
                        {this.renderTab('users')}
                        {this.renderTab('scopes')}
                        {this.renderTab('sites')}
                    </div>
                </div>
            </nav>
        );
    }
}


export default Navbar;


