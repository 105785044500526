
import './Website.css';
import React from 'react';

import {
    CardProps
} from "./CardProps";

import {
    Button,
    Columns
} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe, faPencil, faSave, faTrashCan, faFileText} from "@fortawesome/free-solid-svg-icons";

interface WebsiteProps{
    url: string;
    explanation: string;
}


interface WebsiteState{
    currUrl: string;
    currExplanation: string;
    isChanging: boolean;
}


export class Website extends React.Component<CardProps, WebsiteState>{
    constructor(props: CardProps) {
        super(props);

        this.state = {
            currUrl: (props.data as WebsiteProps).url,
            currExplanation: (props.data as WebsiteProps).explanation,
            isChanging: false
        };
    }

    componentWillUpdate(nextProps: Readonly<CardProps>, nextState: Readonly<WebsiteState>, nextContext: any) {
        if (nextProps.id !== this.props.id){
            this.setState({
                currUrl: (nextProps.data as WebsiteProps).url,
                isChanging: false
            })
        }
    }

    render(): JSX.Element{
        const props = this.props.data as WebsiteProps;

        return (
            <>
                <Columns>
                    <Columns.Column className="is-12">
                        {
                            ! this.state.isChanging ?
                                <React.Fragment>
                                    <h1 className="url has-background-warning">
                                        <FontAwesomeIcon icon={faGlobe} className="mr-3"/>
                                        {props.url}
                                    </h1>

                                    <h1 className="url has-background-light">
                                        <FontAwesomeIcon icon={faFileText} className="mr-3"/>
                                        {props.explanation}
                                    </h1>
                                </React.Fragment>
                                :
                                <Columns>
                                    <Columns.Column>
                                        <div className="control has-icons-left">
                                            <input
                                                className="input is-rounded"
                                                type="text"
                                                defaultValue={props.url}
                                                onChange={(event) => {
                                                    this.setState({
                                                        currUrl: event.target.value
                                                    });
                                                }}
                                            />

                                            <span className="icon is-small is-left">
                                                <FontAwesomeIcon icon={faGlobe} />
                                            </span>
                                        </div>
                                    </Columns.Column>

                                    <Columns.Column>
                                        <div className="control has-icons-left">
                                            <input
                                                className="input is-rounded"
                                                type="text"
                                                defaultValue={props.explanation}
                                                onChange={(event) => {
                                                    this.setState({
                                                        currExplanation: event.target.value
                                                    });
                                                }}
                                            />

                                            <span className="icon is-small is-left">
                                                <FontAwesomeIcon icon={faFileText} />
                                            </span>
                                        </div>
                                    </Columns.Column>
                                </Columns>
                        }
                    </Columns.Column>

                    <Columns.Column className="buttons">
                        {
                            ! this.state.isChanging ?
                                <Button
                                    className="is-primary is-rounded"
                                    onClick={() => {
                                        this.setState({isChanging: true});
                                    }}
                                >
                                    <span>
                                        <FontAwesomeIcon icon={faPencil} className="mr-3"/>
                                    </span>
                                    <span>
                                        Edit
                                    </span>
                                </Button>
                                :
                                <>
                                    <Button
                                        className="is-danger is-rounded"
                                        onClick={() => {
                                            this.setState({
                                                currUrl: props.url
                                            });

                                            this.setState({isChanging: false});
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="is-primary is-rounded"
                                        onClick={async () => {
                                            const success = await this.props.sendRequest(
                                                'PUT',
                                                `admin/site/${this.props.id}/`,
                                                {
                                                    url: this.state.currUrl,
                                                    explanation: this.state.currExplanation
                                                },
                                                `Set Website: ${this.props.id} URL to: ${this.state.currUrl} ?`,
                                                () => {
                                                    this.setState({isChanging: false});
                                                }
                                            );
                                        }}
                                    >
                                        <span>
                                            <FontAwesomeIcon icon={faSave} className="mr-3"/>
                                        </span>
                                        <span>
                                            Save
                                        </span>
                                    </Button>
                                </>
                        }
                    </Columns.Column>

                    <Columns.Column className="is-12 mt-6">
                        <div className="buttons">
                            {
                                this.state.isChanging ?
                                    <Button
                                        className="is-danger is-rounded"
                                        onClick={async () => {
                                            const success = await this.props.sendRequest(
                                                'DELETE',
                                                `admin/site/${this.props.id}/`,
                                                {},
                                                `Delete Website: ${this.props.id} ?`,
                                                () => {
                                                    this.props.setShownCardIdentifier(null);
                                                }
                                            );
                                        }}
                                    >
                                        <span>
                                            <FontAwesomeIcon icon={faTrashCan} className="mr-3"/>
                                        </span>
                                        <span>
                                            Delete Website
                                        </span>
                                    </Button>
                                    :
                                    null
                            }

                        </div>
                    </Columns.Column>
                </Columns>
            </>
        )
    }
}