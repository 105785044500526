
import './Modal.css';
import React from "react";
import 'bulma/css/bulma.min.css';
import '../general_styles.css';

// @ts-ignore
import successIcon from '../img/check.png';
// @ts-ignore
import failIcon from '../img/x_image.png';
// @ts-ignore
import loadingIcon from "../img/loading.png";

import {Button, Columns} from "react-bulma-components";


interface Props{
    render: boolean;
    setRender: Function;
    dialogMessage: string;
    onYes: Function;
    onNo: Function;
}

interface State{
    status: 'dialog' | 'loading' | 'success' | 'fail';
    failMessage: string;
}


export class Modal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            status: 'dialog',
            failMessage: ''
        };
    }

    renderSuccess(): JSX.Element{
        return (
            <img src={successIcon} alt="success-icon"/>
        );
    }

    renderFail(): JSX.Element{
        return (
            <Columns>
                <Columns.Column className="is-12 has-text-centered">
                    <h1 className="text"><strong>{this.state.failMessage}</strong></h1>
                </Columns.Column>
                <Columns.Column className="is-12">
                    <img src={failIcon} alt="fail-icon"/>
                </Columns.Column>
            </Columns>
        );
    }

    renderDialog(): JSX.Element{
        return (
            <Columns>
                <Columns.Column className="is-12 has-text-centered">
                    <h1 className="text">{this.props.dialogMessage}</h1>
                </Columns.Column>
                <Columns.Column className="is-12 has-text-centered">
                    <Button className="is-success" onClick={this.onYes.bind(this)}> Yes </Button>
                    <Button className="ml-3" onClick={this.onNo.bind(this)}> No </Button>
                </Columns.Column>
            </Columns>
        );
    }

    renderLoading(): JSX.Element{
        return (
            <img src={loadingIcon} alt="loading" className="spin has-text-centered"/>
        );
    }

    onNo(){
        this.props.onNo();
        this.props.setRender(false);
        this.setState({
            status: 'dialog'
        });
    }

    async onYes(){
        await this.setState({status: 'loading'});
        const result = await this.props.onYes();

        if (result.success){
            await this.setState({status: 'success'});
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.onClose();
        } else {
            this.setState({
                status: 'fail',
                failMessage: result.failMessage
            });
        }
    }

    onClose(){
        this.props.setRender(false);
        this.setState({status: 'dialog'});
    }

    render(): JSX.Element | null{
        if (! this.props.render){
            return null;
        }

        // else:

        return (
            <div className="modal is-active">
                <div className="modal-background" onClick={() => {
                    if (this.state.status !== 'loading'){
                        this.onClose();
                    }
                }}></div>
                <div className="modal-content">
                    <div className="box has-text-centered">
                        {
                            this.state.status === 'dialog' ?
                                this.renderDialog()
                                :
                                this.state.status === 'success' ?
                                    this.renderSuccess()
                                    :
                                    this.state.status === 'fail' ?
                                        this.renderFail()
                                        :
                                        this.renderLoading()
                        }
                    </div>
                </div>

            </div>
        );
    }
}