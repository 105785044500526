
import './App.css';
import React from "react";
import 'bulma/css/bulma.min.css';

import {
    Columns
} from 'react-bulma-components';

import {
    Menu
} from './elements/Menu';


import axios from "axios";
import {getCookie, setCookie} from "./cookies";
import Navbar from "./elements/Navbar";
import {Scope} from "./elements/cards/Scope";
import {User} from "./elements/cards/User";
import {Website} from "./elements/cards/Website";
import {AddUser} from "./elements/addCards/AddUser";
import {AddWebsite} from "./elements/addCards/AddWebsite";
import {AddScope} from "./elements/addCards/scope/AddScope";

import {Modal} from "./elements/Modal";
// @ts-ignore
import backgroundImage from './img/background.png';


interface State{
    totalMetaData: any;
    shownUser: string | null;
    shownScope: string | null;
    shownWebsite: string | null;
    selectedTab: 'users' | 'scopes' | 'sites';

    render: boolean;

    userAdded: boolean;
    scopeAdded: boolean;
    websiteAdded: boolean;

    renderModal: boolean;
    modalDialogMessage: string;
    modalOnYesFunction: Function;
    modalOnNoFunction: Function;
}

export default class App extends React.Component<{ }, State>{

    constructor(props: {}) {
        super(props);

        this.state = {
            totalMetaData: {},

            shownUser: null,
            shownScope: null,
            shownWebsite: null,
            selectedTab: 'users',

            render: false,

            userAdded: false,
            scopeAdded: false,
            websiteAdded: false,

            renderModal: false,
            modalDialogMessage: '',
            modalOnYesFunction: () => {},
            modalOnNoFunction: () => {}
        };
    }

    async sendRequest(
        method: string,
        endpoint: string,
        params: object,

        dialogMessage: string,
        onSuccess: Function
    ){
        await this.setState({
            renderModal: true,

            modalDialogMessage: dialogMessage,
            modalOnYesFunction: async () => {
                const metaData = {
                    'users': {},
                    'scopes': {},
                    'sites': {}
                };
                let failMessage = null;

                await axios.request({
                    method: method,
                    url: `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/${endpoint}`,
                    data: params,
                    headers: {
                        "Content-Type": "application/json",
                        "Secret-Key": process.env.REACT_APP_SECRET_KEY as string,
                        Authorization: `Bearer ${getCookie('jwt')}`
                    }
                })
                    .then(async (response) => {
                        for (const resource of ['users', 'scopes', 'sites']) {
                           await axios.get(
                               `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/admin/${resource}/`,
                               {
                                   headers: {
                                       "Content-Type": "application/json",
                                       "Secret-Key": process.env.REACT_APP_SECRET_KEY as string,
                                       Authorization: `Bearer ${getCookie('jwt')}`
                                   }
                               }
                           ).then(async (response) => {
                               const data = response.data[resource];
                               // @ts-ignore
                               metaData[resource] = {}
                               data.forEach((item: any) => {
                                   switch(resource){
                                       case 'users':
                                           // @ts-ignore
                                           metaData[resource][item['email']] = item
                                           break;
                                       case 'scopes':
                                           // @ts-ignore
                                           metaData[resource][item['name']] = item
                                           break;
                                       case 'sites':
                                           // @ts-ignore
                                           metaData[resource][item['name']] = item
                                           break;
                                   }
                               })
                           });
                        }

                        setCookie('jwt', response.data.jwt, 356 * 100000);
                    })
                    .catch(async (error) => {
                        const response = await error.response;
                        const data = response.data;

                        if ('message' in data){
                            // The request was processed but wasn't legal
                            failMessage = data.message;
                        } else {
                            if (response.status >= 500){
                                failMessage = "Something went wrong with the server";
                            } else {
                                if (response.status >= 400){
                                    failMessage = "You're not authorized to control the authorization process anymore, " +
                                        "you can ask another Admin member to promote you to Admin again";
                                } else {
                                    failMessage = "Unknown error";
                                }
                            }
                        }
                    });

                if (failMessage === null){
                    this.setState({
                        totalMetaData: metaData
                    });

                    onSuccess();
                }

                return {
                    success: (failMessage === null),
                    failMessage: failMessage
                };
            },
            modalOnNoFunction: () => {}
        });
    }

    async componentDidMount() {
        await axios.post(
            `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/user/auth/`,
            {
                "scope": process.env.REACT_APP_ADMIN_SCOPE_NAME as string
            },
            {
                headers: {
                    "Secret-Key": process.env.REACT_APP_SECRET_KEY as string,
                    Authorization: `Bearer ${getCookie('jwt')}`
                }
            }
        )
            .then(async (response) => {
                // user is authorized (jwt cookie is valid)
            })
            .catch((error) => {
                // set a cookie that tells the authentication website where to go to
                setCookie(
                    'redirection',
                    window.location.href,
                    365 * 999
                );

                // redirect to the login website
                // window.location.href = `https://${process.env.REACT_APP_AUTHENTICATION_WEBSITE_HOSTNAME}/`
            });

        const metaData = {};

        for (const resource of ['users', 'scopes', 'sites']) {
            await axios.get(
                `${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}/admin/${resource}/`,
                {
                    headers: {
                        "Secret-Key": process.env.REACT_APP_SECRET_KEY as string,
                        Authorization: `Bearer ${getCookie('jwt')}`
                    }
                }
            ).then(async (response) => {
                const data = response.data[resource];
                // @ts-ignore
                metaData[resource] = {}
                data.forEach((item: any) => {
                    switch(resource){
                        case 'users':
                            // @ts-ignore
                            metaData[resource][item['email']] = item
                            break;
                        case 'scopes':
                            // @ts-ignore
                            metaData[resource][item['name']] = item
                            break;
                        case 'sites':
                            // @ts-ignore
                            metaData[resource][item['name']] = item
                            break;
                    }
                })
            });
        }

        this.setState({
            totalMetaData: metaData,
            render: true
        });
    }

    setShownCardIdentifier(id: string): void {
        switch(this.state.selectedTab) {
            case 'users':
                this.setState({
                    shownUser: id
                });
                break;
            case 'scopes':
                this.setState({
                    shownScope: id
                });
                break;
            case 'sites':
                this.setState({
                    shownWebsite: id
                });
                break;
        }
    }

    getShownCardIdentifier(): string | null{
        return {
            'users': this.state.shownUser,
            'scopes': this.state.shownScope,
            'sites': this.state.shownWebsite
        }[this.state.selectedTab];
    }

    setSelectedTab(tabName: 'users' | 'scopes' | 'sites'): void {
        this.setState({
            selectedTab: tabName
        });
    }

    setItemAdded(val: boolean){
        switch(this.state.selectedTab) {
            case 'users':
                this.setState({
                    userAdded: val
                });
                break;
            case 'scopes':
                this.setState({
                    scopeAdded: val
                });
                break;
            case 'sites':
                this.setState({
                    websiteAdded: val
                });
                break;
        }
    }

    getItemAdded(): boolean{
        return {
            'users': this.state.userAdded,
            'scopes': this.state.scopeAdded,
            'sites': this.state.websiteAdded
        }[this.state.selectedTab];
    }

    render(): JSX.Element | null{
        if (! this.state.render){
            return null;
        }

        const tabMetaData = this.state.totalMetaData[this.state.selectedTab];

        const shownCardIdentifier = this.getShownCardIdentifier();

        if (shownCardIdentifier === null){
            this.setShownCardIdentifier(Object.keys(tabMetaData).at(0) as string);
        }

        return (
            <>
                <img src={backgroundImage} alt="background-image" className="background-image"/>
                <Modal
                    render={this.state.renderModal}
                    setRender={(val: boolean) => {this.setState({renderModal: val})}}
                    dialogMessage={this.state.modalDialogMessage}
                    onYes={this.state.modalOnYesFunction}
                    onNo={this.state.modalOnNoFunction}
                />
                <Navbar
                    selectedTab={this.state.selectedTab}
                    setSelectedTab={this.setSelectedTab.bind(this)}
                />
                <Columns>
                    <Columns.Column className="is-4 p-5 menu" style={{padding: '0px'}}>
                        <Menu
                            items={Object.keys(tabMetaData)}
                            shownCardIdentifier={shownCardIdentifier}
                            setShownCardIdentifier={this.setShownCardIdentifier.bind(this)}
                            itemAdded={this.getItemAdded()}
                            setItemAdded={this.setItemAdded.bind(this)}
                        />
                    </Columns.Column>
                    <Columns.Column className="is-8 p-6 has-text-centered showcase">
                        {
                            this.state.selectedTab === 'users' ?
                                this.state.userAdded ?
                                    <AddUser
                                        totalMetadata={this.state.totalMetaData}
                                        sendRequest={this.sendRequest.bind(this)}
                                        setShownCardIdentifier={this.setShownCardIdentifier.bind(this)}
                                        setItemAdded={this.setItemAdded.bind(this)}
                                    />
                                    :
                                    (shownCardIdentifier !== null) ?
                                        <User
                                            id={shownCardIdentifier}
                                            data={tabMetaData[shownCardIdentifier]}
                                            totalMetadata={this.state.totalMetaData}
                                            sendRequest={this.sendRequest.bind(this)}
                                            setShownCardIdentifier={this.setShownCardIdentifier.bind(this)}
                                        />
                                        :
                                        null
                                :
                                this.state.selectedTab === 'scopes' ?
                                    this.state.scopeAdded ?
                                        <AddScope
                                            totalMetadata={this.state.totalMetaData}
                                            sendRequest={this.sendRequest.bind(this)}
                                            setShownCardIdentifier={this.setShownCardIdentifier.bind(this)}
                                            setItemAdded={this.setItemAdded.bind(this)}
                                        />
                                        :
                                        (shownCardIdentifier !== null) ?
                                            <Scope
                                                id={shownCardIdentifier}
                                                data={tabMetaData[shownCardIdentifier]}
                                                totalMetadata={this.state.totalMetaData}
                                                sendRequest={this.sendRequest.bind(this)}
                                                setShownCardIdentifier={this.setShownCardIdentifier.bind(this)}
                                            />
                                            :
                                            null
                                    :
                                    this.state.websiteAdded ?
                                        <AddWebsite
                                            totalMetadata={this.state.totalMetaData}
                                            sendRequest={this.sendRequest.bind(this)}
                                            setShownCardIdentifier={this.setShownCardIdentifier.bind(this)}
                                            setItemAdded={this.setItemAdded.bind(this)}
                                        />
                                        :
                                        (shownCardIdentifier !== null) ?
                                            <Website
                                                id={shownCardIdentifier}
                                                data={tabMetaData[shownCardIdentifier]}
                                                totalMetadata={this.state.totalMetaData}
                                                sendRequest={this.sendRequest.bind(this)}
                                                setShownCardIdentifier={this.setShownCardIdentifier.bind(this)}
                                            />
                                            :
                                            null
                        }
                    </Columns.Column>
                </Columns>
            </>
        )
    }
}