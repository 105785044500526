
export function getCookie(
    cname: string
): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}


export function setCookie(
    cname: string,
    cvalue: string,
    exdays: number
) {
    let dt, expires;
    dt = new Date();
    dt.setTime(dt.getTime()+(exdays*24*60*60*1000));
    expires = "; expires="+dt.toUTCString();

    document.cookie = cname+"="+cvalue+expires+'; domain=' + process.env.REACT_APP_WEBSITES_DOMAIN;
}
