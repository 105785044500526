
import './Menu.css';
import '../general_styles.css';
import React from "react";
import {
    Columns
} from "react-bulma-components";

interface Props{
    items: string[];
    shownCardIdentifier: string | null;
    setShownCardIdentifier: Function;
    itemAdded: boolean;
    setItemAdded: Function;
}


export class Menu extends React.Component<Props, { }> {
    render() {
        return (
            <Columns className="is-multiline p-5 menu">
                <Columns.Column
                    className={"is-12 mb-4 has-text-centered item has-background-primary clickable" +
                        (this.props.itemAdded ? ' has-background-info' : '')
                    }
                    onClick={() => {
                        this.props.setItemAdded(true);
                    }}
                >
                    +
                </Columns.Column>
                {this.props.items.map(
                    (item: string, index: number) => {
                        return (
                            <Columns.Column
                                className={"is-12 mb-4 has-text-centered item clickable" +
                                    (((this.props.shownCardIdentifier === item) && (!this.props.itemAdded))? ' has-background-info' : '')
                                }
                                onClick={() => {
                                    this.props.setItemAdded(false);
                                    this.props.setShownCardIdentifier(item);
                                }}
                                key={index}
                            >
                                <h1>{item}</h1>
                            </Columns.Column>
                        );
                    }
                )}
            </Columns>
        );
    }
}