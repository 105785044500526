
import React from 'react';

import {
    AddCardProps
} from "./AddCardProps";

import {
    Columns,
    Button
} from "react-bulma-components";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faGlobe, faSave, faFileText} from "@fortawesome/free-solid-svg-icons";


interface State{
    websiteName: string;
    websiteUrl: string;
    websiteExplanation: string;
}


export class AddWebsite extends React.Component<AddCardProps, State>{

    constructor(props: AddCardProps) {
        super(props);

        this.state = {
            websiteName: '',
            websiteUrl: '',
            websiteExplanation: ''
        };
    }

    render(): JSX.Element{
        return (
            <>
                <Columns className="is-multiline add-website">

                    <Columns.Column className="is-12 name">
                        <div className="control has-icons-left">
                            <input
                                className="input is-rounded"
                                type="text"
                                placeholder="Name"
                                onChange={(event) => {
                                    this.setState({
                                        websiteName: event.target.value,
                                    });
                                }}
                            />

                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faPencil} />
                            </span>
                        </div>
                    </Columns.Column>

                    <Columns.Column className="is-12">
                        <div className="control has-icons-left">
                            <input
                                className="input is-rounded"
                                type="text"
                                placeholder="URL"
                                onChange={(event) => {
                                    this.setState({
                                        websiteUrl: event.target.value,
                                    });
                                }}
                            />

                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faGlobe} />
                            </span>
                        </div>
                    </Columns.Column>

                    <Columns.Column className="is-12">
                        <div className="control has-icons-left">
                            <input
                                className="input is-rounded"
                                type="text"
                                placeholder="Short Explanation"
                                onChange={(event) => {
                                    this.setState({
                                        websiteExplanation: event.target.value,
                                    });
                                }}
                            />

                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faFileText} />
                            </span>
                        </div>
                    </Columns.Column>

                    <Columns.Column className="is-12 mt-6">
                        <div className="buttons">
                            <Button
                                className="is-success is-rounded"
                                onClick={async () => {
                                    const success = await this.props.sendRequest(
                                        'POST',
                                        `admin/site/${this.state.websiteName}/`,
                                        {
                                            url: this.state.websiteUrl,
                                            explanation: this.state.websiteExplanation
                                        },
                                        `Add Website: ${this.state.websiteName} with URL: ${this.state.websiteUrl} ?`,
                                        () => {
                                            this.props.setShownCardIdentifier(this.state.websiteName);
                                            this.props.setItemAdded(false);
                                        }
                                    );
                                }}
                            >
                                <span>
                                    <FontAwesomeIcon icon={faSave} className="mr-3"/>
                                </span>
                                <span>
                                    Save
                                </span>
                            </Button>

                            <Button
                                className="is-danger is-rounded"
                                onClick={() => {
                                    this.props.setItemAdded(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Columns.Column>
                </Columns>
            </>
        )
    }
}