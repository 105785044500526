
import './User.css';
import React from 'react';

// @ts-ignore
import defaultUserImage from '../../img/user.png';

import {
    CardProps
} from "./CardProps";

import {
    Columns,
    Button
} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrashCan, faSave, faHatCowboy, faRefresh} from "@fortawesome/free-solid-svg-icons";

interface UserProps{
    email: string;
    scope: string;
    name: string;
    picture: string;
}

interface State{
    selectedScope: string;
    imageErrored: boolean;
    isChanging: boolean;
}


export class User extends React.Component<CardProps, State>{

    constructor(props: CardProps) {
        super(props);

        this.state = {
            selectedScope: (this.props.data as UserProps).scope,
            imageErrored: false,
            isChanging: false
        };
    }

    componentWillUpdate(nextProps: Readonly<CardProps>, nextState: Readonly<State>, nextContext: any) {
        if ((nextProps.data as UserProps).picture !== ((this.props.data) as UserProps).picture) {
            this.setState({
                selectedScope: (this.props.data as UserProps).scope,
                imageErrored: false,
                isChanging: false
            });
        }
    }

    render(): JSX.Element{
        const props = this.props.data as UserProps;
        const allScopes = Object.keys(this.props.totalMetadata.scopes);

        return (
            <>
                <Columns className="is-multiline user">

                    <Columns.Column className="is-12 picture">
                        <img
                            src={(props.picture === null || props.picture === undefined || this.state.imageErrored) ? defaultUserImage : props.picture}
                            alt='user picture'
                            style={{height: '150px'}}
                            onError={() => {
                                this.setState({
                                    imageErrored: true
                                })
                            }}
                        />
                    </Columns.Column>

                    <Columns.Column className="is-12 name">
                        <h1>{props.name}</h1>
                    </Columns.Column>

                    <Columns.Column className="is-12 mt-3 scope">
                        <Columns>
                            <Columns.Column>
                                {
                                    !this.state.isChanging ?
                                        <h1 className="name has-background-warning">
                                            <FontAwesomeIcon icon={faHatCowboy} className="mr-3"/>
                                            {props.scope}
                                        </h1>
                                        :
                                        <div className="select is-rounded">
                                            <select
                                                onChange={(event) => {
                                                    this.setState({
                                                        selectedScope: event.target.value
                                                    });
                                                }}
                                            >
                                                <option value={props.scope}>{props.scope}</option>
                                                {
                                                    allScopes.map(
                                                        (scopeName: string, index: number) => {
                                                            if (scopeName === props.scope) {
                                                                return null;
                                                            }

                                                            return (
                                                                <option value={scopeName} key={index}>
                                                                    {scopeName}
                                                                </option>
                                                            );
                                                        }
                                                    )
                                                }
                                            </select>
                                        </div>
                                }
                            </Columns.Column>
                            <Columns.Column>
                                {
                                    !this.state.isChanging ?
                                        <div className="buttons">
                                            <Button
                                                className="is-primary is-rounded"
                                                onClick={() => {
                                                    this.setState({isChanging: true});
                                                }}
                                            >
                                                <span>
                                                    <FontAwesomeIcon icon={faPencil} className="mr-2"/>
                                                </span>
                                                <span>Edit</span>
                                            </Button>
                                        </div>
                                        :
                                        <div className="buttons">
                                            <Button
                                                className="is-danger is-rounded"
                                                onClick={() => {
                                                    this.setState({isChanging: false});
                                                }}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                className="is-primary is-rounded"
                                                onClick={async () => {
                                                    const success = await this.props.sendRequest(
                                                        'PUT',
                                                        `admin/user/${this.props.id}/scope/`,
                                                        {
                                                            scope: this.state.selectedScope
                                                        },
                                                        `Change User: ${this.props.id} Scope from: ${props.scope} to: ${this.state.selectedScope} ?`,
                                                        () => {
                                                            this.setState({isChanging: false});
                                                        }
                                                    );
                                                }}
                                            >
                                                <span className="icon is-small">
                                                    <FontAwesomeIcon icon={faSave} />
                                                </span>
                                                <span>Save</span>
                                            </Button>

                                            <Button
                                                className="is-warning is-rounded"
                                                onClick={async () => {
                                                    const success = await this.props.sendRequest(
                                                        'POST',
                                                        `admin/user/${this.props.id}/google-authenticator/deactivate/`,
                                                        {},
                                                        `Let User: ${this.props.id} set up his google authenticator again ?`,
                                                        () => {
                                                            this.setState({isChanging: false});
                                                        }
                                                    );
                                                }}
                                            >
                                                <span className="icon is-small">
                                                    <FontAwesomeIcon icon={faRefresh} />
                                                </span>
                                                <span>Reset google authenticator</span>
                                            </Button>
                                        </div>
                                }
                            </Columns.Column>
                        </Columns>
                    </Columns.Column>

                    <Columns.Column className="is-12 mt-6">
                        <div className="buttons">
                            {
                                this.state.isChanging ?
                                    <Button
                                        className="is-danger is-rounded"
                                        onClick={async () => {
                                            const success = await this.props.sendRequest(
                                                'DELETE',
                                                `admin/user/${this.props.id}/`,
                                                {},
                                                `Delete User: ${this.props.id} ?`,
                                                () => {
                                                    this.props.setShownCardIdentifier(null);
                                                }
                                            );
                                        }}
                                    >
                                        <span className="icon is-small">
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </span>
                                        <span>Delete User</span>
                                    </Button>
                                    :
                                    null
                            }
                        </div>
                    </Columns.Column>
                </Columns>


            </>
        )
    }
}