
import React from 'react';

import {
    Button,
    Columns
} from "react-bulma-components";


interface Props{
    allWebsitesNames: string[];

    chosenWebsitesNames: string[];
    setChosenWebsitesNames: Function;
}


export class ManageScopes extends React.Component<Props, any>{
    render() {
        return (
            <Columns style={{maxHeight: '70vh', overflow: 'auto'}}>
                <Columns.Column className="is-6">
                    <Columns.Column className="is-12" style={{borderBottom: '1px solid black', marginBottom: '10px'}}>
                        Available Sites
                    </Columns.Column>
                    {
                        this.props.chosenWebsitesNames.map(
                            (siteName: string, index: number) => {
                                const backgroundColor = {
                                    0: 'primary',
                                    1: 'link',
                                    2: 'warning',
                                    3: 'info',
                                    4: 'danger',
                                    5: 'success'
                                }[index % 6];
                                return (
                                    <Columns.Column
                                        className={`box has-background-${backgroundColor} is-12`} key={index}
                                    >
                                        {siteName}
                                        <Button
                                            style={{position: 'relative', float: 'right', maxHeight: '25px'}}
                                            onClick={() => {
                                                this.props.setChosenWebsitesNames(
                                                    this.props.chosenWebsitesNames.filter(
                                                        (name: string) => name !== siteName
                                                    )
                                                );
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </Columns.Column>
                                );
                            }
                        )
                    }
                </Columns.Column>
                <Columns.Column className="is-6" style={{borderLeft: '1px solid black'}}>
                    <Columns.Column className="is-12" style={{borderBottom: '1px solid black', marginBottom: '10px'}}>
                        Unavailable Sites
                    </Columns.Column>
                    {
                        this.props.allWebsitesNames.map(
                            (siteName: string, index: number) => {
                                const backgroundColor = {
                                    0: 'primary',
                                    1: 'link',
                                    2: 'warning',
                                    3: 'info',
                                    4: 'danger',
                                    5: 'success'
                                }[index % 6];

                                if (this.props.chosenWebsitesNames.includes(siteName)){
                                    return null;
                                }

                                return (
                                    <Columns.Column
                                        className={`box has-background-${backgroundColor} is-12`} key={index}
                                    >
                                        {siteName}
                                        <Button
                                            style={{position: 'relative', float: 'right', maxHeight: '25px'}}
                                            onClick={() => {
                                                this.props.setChosenWebsitesNames(
                                                    [...this.props.chosenWebsitesNames, siteName]
                                                );
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </Columns.Column>
                                );
                            }
                        )
                    }
                </Columns.Column>
            </Columns>
        )
    }
}